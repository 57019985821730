<template>
    <div class="h-chart">
        <div :id="id" class="chart"></div>
    </div>
</template>

<script>
    import HighCharts from 'highcharts/highstock';
    import HighChartsMore from 'highcharts/highcharts-more';
    import HighCharts3D from 'highcharts/highcharts-3d';
    HighChartsMore(HighCharts);
    HighCharts3D(HighCharts)
    export default {
        name: "HChart",
        props: {
            id: {
                type: String
            },
            options: {
                type: Object
            }
        },
        methods:{
            redraw(){
                HighCharts.chart(this.id, this.options)
            }
        },
        mounted() {
            HighCharts.setOptions({
                lang:{
                    resetZoom: '重置缩放'
                }
            });
            HighCharts.chart(this.id, this.options)
        },
        watch: {
            options: {
                handler() {
                    HighCharts.chart(this.id, this.options);
                },
            },
        },
    }
</script>

<style scoped>

</style>
